<template>
  <div :class="{'monitor-view-wrapper': isMonitorView}" id="monitor-view">
    <div class="mt-4 px-5">

      <h2>Heute</h2>

      <div :class="{'monitor-view': isMonitorView, 'normal-view': !isMonitorView}">
        <v-data-table
            v-if="show_SL"
            :class="{'large-table': isMonitorView}"
            :headers="headers_SL"
            :items="comp_TodaySL"
            class="elevation-1 my-2"
            ref="TodaySL"
            no-data-text="Kein Schiessleiter eingebucht"
            hide-default-footer
        >
          <template v-slot:top>
            <v-toolbar flat color="warning">
              <h3>Schiessleiter</h3>
              <v-spacer></v-spacer>
              <v-btn icon @click="getTodaySL">
                <v-icon>mdi-reload</v-icon>
              </v-btn>
            </v-toolbar>
          </template>
        </v-data-table>

        <v-data-table
            v-if="show_SA"
            :class="{'large-table': isMonitorView}"
            :headers="headers_SA"
            :items="comp_TodaySA"
            class="elevation-1 my-2"
            ref="TodaySA"
            no-data-text="Kein Standaufischt eingebucht"
            hide-default-footer
        >
          <template v-slot:top>
            <v-toolbar flat color="info">
              <h3>Standaufsicht</h3>
              <v-spacer></v-spacer>
              <v-btn icon @click="getTodaySA">
                <v-icon>mdi-reload</v-icon>
              </v-btn>
            </v-toolbar>
          </template>
        </v-data-table>
      </div>

      <v-data-table
          v-if="show_user"
          :headers="headers"
          :items="comp_TodayMember"
          sort-by="KurzBez"
          group-by="KurzBez"
          class="elevation-1 my-2"
          ref="TodayMember"
          no-data-text="Kein Schütze eingebucht"
      >
        <template v-slot:top>
          <v-toolbar flat color="success">
            <h3>Schützen</h3>
            <v-spacer></v-spacer>
            <v-btn icon @click="getTodayMember">
              <v-icon>mdi-reload</v-icon>
            </v-btn>
          </v-toolbar>
        </template>
      </v-data-table>

      <div v-if="isMonitorView" class="clock-container">
        <div class="clock-overlay">
          {{ currentTime }}
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import client, {initializeAblyClient} from "@/plugins/ably";

export default {
  name: 'today',
  data: () => ({
    polling: null,
    dialog: false,
    dialogDelete: false,
    loading: false,
    show_SL: true,
    show_SA: true,
    show_user: true,
    channel: null,
    channel2: null,
    range_id_param: null,
    isMonitorView: false,
    currentTime: '',
    headers: [
      {text: 'Name', value: 'name', align: 'left', sortable: false},
      {text: 'Vorname', value: 'vorname'},
      {text: 'Stand', value: 'KurzBez'},
      {text: 'Bahn', value: 'bahn'},
      {text: 'Funktion', value: 'Aufsicht'},
      {text: 'Begin', value: 'BeginFormat'}
    ],
    headers_SA: [
      {text: 'Name', value: 'name', align: 'left', sortable: false},
      {text: 'Vorname', value: 'vorname'},
      {text: 'Stand', value: 'KurzBez'},
      {text: 'Begin', value: 'BeginFormat'}
    ],
    headers_SL: [
      {text: 'Name', value: 'name', align: 'left', sortable: false},
      {text: 'Vorname', value: 'vorname'},
      {text: 'Begin', value: 'BeginFormat'}
    ],
  }),

  computed: {
    comp_TodayMember() {
      return this.$store.state.ClubApp.TodayMember;
    },
    comp_TodaySA() {
      const SAList = this.$store.state.ClubApp.TodaySA;
      if (this.range_id_param) {
        return SAList.filter(sa => this.range_id_param.includes(sa.shootingrange_id));
      }
      return SAList;
    },
    comp_TodaySL() {
      return this.$store.state.ClubApp.TodaySL;
    },
    isClubLoggedIn() {
      return this.$store.getters["ClubAuth/isClubLoggedIn"];
    },
    isClubID() {
      return this.$store.getters["ClubAuth/isClubID"];
    },
  },
  created() {
    const params = this.$route.params;
    if (Object.keys(params).length === 0) {
      this.$store.commit('GeneralApp/SET_SHOW_NAVIGATION', true);
      this.start_websocket();
    } else {
      this.isMonitorView = true;
      this.loginwithToken();
      this.updateTime();
      this.applyMonitorViewStyles();
    }
  },
  beforeDestroy() {
    if (this.channel) this.channel.unsubscribe();
    if (this.channel2) this.channel2.unsubscribe();
    if (this.isMonitorView) {
      this.removeMonitorViewStyles();
    }
  },
  methods: {
    async start_websocket() {
      await initializeAblyClient();

      await client.connection.on((stateChange) => {
        if (stateChange.current === 'disconnected') {
          this.start_websocket();
        }
      });

      this.channel = await client.channels.get('private:clubs.' + this.isClubID + '.ClubTodayChannel');
      this.channel2 = await client.channels.get('clubs/' + this.isClubID + '/range_safty');

      await this.channel.subscribe((message) => {
        this.$store.commit('ClubApp/get_TodaySA', message.data.message.TodaySA);
        this.$store.commit('ClubApp/get_TodaySL', message.data.message.TodaySL);
      });

      await this.channel2.subscribe((msg) => {
        const jsonString = new TextDecoder().decode(msg.data);
        try {
          const data = JSON.parse(jsonString);
          console.log(data);
        } catch (e) {
          console.error("Konnte den Nachrichteninhalt nicht als JSON parsen", e);
        }
      });
    },
    updateTime() {
      setInterval(() => {
        const now = new Date();
        this.currentTime = now.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'});
      }, 1000);
    },
    getTodayMember() {
      if (this.show_user) {
        this.$store.dispatch('ClubApp/getTodayMember');
      }
    },
    getTodaySA() {
      if (this.show_SA) {
        this.$store.dispatch('ClubApp/getTodaySA');
      }
    },
    getTodaySL() {
      if (this.show_SL) {
        this.$store.dispatch('ClubApp/getTodaySL');
      }
    },
    async loginwithToken() {
      const params = this.$route.params;
      const token = params.token;
      const clubid = params.clubid;

      if (params.rangeid) {
        this.range_id_param = params.rangeid.split(',').map(Number);
      } else {
        this.range_id_param = null;
      }

      const data = {
        clubid: clubid,
        token: token
      };

      this.show_user = false;
      this.show_SL = false;
      this.show_SA = true;

      await this.$store.dispatch('ClubAuth/login', data)
          .then(() =>
              this.$store.commit('GeneralApp/SET_SHOW_NAVIGATION', false)
          )
          .catch(err => console.log(err))
          .finally(this.start_websocket)
          .finally(this.getTodaySA)
          .finally(this.getTodayMember)
          .finally(this.getTodaySL)
          .finally(this.applyMonitorViewStyles);
    },
    applyMonitorViewStyles() {
      document.documentElement.classList.add('monitor-view');
      document.body.classList.add('monitor-view');
    },
    removeMonitorViewStyles() {
      document.documentElement.classList.remove('monitor-view');
      document.body.classList.remove('monitor-view');
    }
  },
}
</script>

<style>
.monitor-view {
  font-size: 1.5rem;
}

/* Stile für den Monitor-View */
html.monitor-view,
body.monitor-view {
  overflow: hidden;
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

/* Nur für die .large-table */
.large-table .v-data-table-header th {
  font-size: 1.5rem !important; /* Anpassung des Tabellen-Headers */
  padding: 16px !important;
}

.large-table .v-data-table__wrapper td {
  font-size: 2rem !important; /* Anpassung des Tabellen-Bodys */
  padding: 16px !important;
  line-height: 2.5rem !important;
}

.clock-container {
  position: relative;
}

.clock-overlay {
  position: fixed;
  bottom: 20px;
  left: 20px;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 20px;
  border-radius: 8px;
  font-size: 5rem;
  z-index: 1000;
}
</style>