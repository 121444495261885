import * as Ably from 'ably';
import store from '../store';

const baseURL = process.env.VUE_APP_API_URL + process.env.VUE_APP_API_VERSION;

let client;

export function initializeAblyClient() {
    var Club_Bearer_Token = store.getters['ClubAuth/isClubToken'];

    if (client) {
        client.close();
    }

    client = new Ably.Realtime({
        authUrl: baseURL + '/club/ably/token',
        authMethod: 'POST',
        authHeaders: {
            'Authorization': `Bearer ${Club_Bearer_Token}`
        }
    });
}

export {client as default};